import { createStore } from 'redux';
import reducers from './reducers';

// eslint-disable-next-line import/prefer-default-export
export function configureStore(initialState) {
  const store = createStore(reducers, initialState);

  if (module.hot) {
    module.hot.accept('./reducers', () => {
      // eslint-disable-next-line global-require
      const nextRootReducer = require('./reducers');
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
}
